.scroll {
    opacity: 0.3;
    background-color: black;
    color: white;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 5px;
    border: none;
    z-index: 100;
}

.scroll:hover {
    opacity: 1;
}

@media screen and (max-width: 600px) {
    .scroll {
        width: 40px;
        height: 40px;
    }
}