.move-image-up {
    margin-top: -100px;
}

#person-card .card {
    transition: .5s;
}

#person-card .card:hover {
    background: #cccccc;
    transform: scale(1.03);
    box-shadow: black;
}