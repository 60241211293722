#apppage {
  background-color: whitesmoke;
}

.separator-line {
  width: 100%;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
}

h1 {
  /* Extra small devices (phones, less than 768px) */
  font-size: 35px !important;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  h1 {
    font-size: 40px !important;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
