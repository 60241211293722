.navbar-nav .nav-item a {
    color: black !important;
}

.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

.navbar-nav li a {
    color: white;
}

.navbar-nav .dropdown-item {
    color: black;
}

.active-link {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.profile-menu:hover {
    background-color: #007bff !important;
}

.profile-menu-active {
    background-color: rgba(0, 122, 255, 0.38) !important;
}

.menu-item-hover:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

.custom-toggler.navbar-toggler {
    border-color: white;
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.hamburger-menu {
    background: white;
}

.user-picture {
    width: 35px;
    height: 35px;
}

/* Navbar and logo animation */

#main-nav {
    box-shadow: 0 1px 11px rgba(0, 0, 0, 1);
}

.navbar-solid {
    padding: 0 10px !important;
    background-color: rgb(55, 71, 68);
}

.navbar-transparent {
    padding: 10px 10px !important;
    background-color: rgba(55, 71, 68, 0.4);
}

#logo {
    font-size: 30px;
}

@media (min-width: 768px) {
    #main-nav {
        transition: 0.8s;
        box-shadow: 0 1px 11px rgba(0, 0, 0, 1);
    }

    #logo {
        transition: 0.8s;
        font-size: 30px;
    }
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.2em;
    line-height: 0.9em;
    border-style: solid;
    border-width: 0.05em;
    border-color: black;
}

.flag-icon:before {
    content: "\00a0";
}

.flag-icon-hu {
    background-image: url("/pictures/flags/hu.svg");
}

.flag-icon-gb {
    background-image: url("/pictures/flags/gb.svg");
}

.flag-icon-rs {
    background-image: url("/pictures/flags/rs.svg");
}

.flag-icon-pl {
    background-image: url("/pictures/flags/pl.svg");
}