.fab {
    color: black;
}

.social-icon a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 0 10px;
    display: block;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    border: 1px solid gray;
    z-index: 1;
}

.social-icon a .icon {
    position: relative;
    color: #262626;
    transition: .3s;
    z-index: 3;
}

.social-icon a:hover .icon {
    color: #fff;
}

.social-icon a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .3s;
    z-index: 2;
}

.social-icon a:hover:before {
    top: 0;
}

.si-facebook a:before{
    background: #3b5999;
}

.si-mail a:before{
    background: #f39a16;
}

.si-web a:before{
    background: #0a760f;
}

.si-phone a:before{
    background: #010076;
}

.si-address a:before{
    background: #359e9c;
}