.dark-overlay {
    background: rgba(0, 0, 0, 0.2) no-repeat center;
    background-size: cover;
}

.space-bottom {
    padding-bottom: 100px;
}

.head-box-background {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.9);
    animation: 0.5s ease-out 0s 1 slideIn;
}

@media (min-width: 1200px) {
    .space-bottom {
        padding-bottom: 150px;
    }

    @keyframes slideIn {
        0% {
            transform: translateY(-30%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}