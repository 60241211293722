hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid gray;
    margin: 1em 0;
    padding: 0;
}

.title-position {
    padding-top: 10px;
}