.card-shadow {
    -webkit-box-shadow: 1px 3px 6px 2px rgba(0,0,0,0.51);
    -moz-box-shadow: 1px 3px 6px 2px rgba(0,0,0,0.51);
    box-shadow: 1px 3px 6px 2px rgba(0,0,0,0.51);
}

.rectangle {
    height: 3px;
    width: 100%;
    background-color: #f39a16;
}