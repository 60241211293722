.section-image-fluid {
    min-width: 100%;
    width: auto;
    height: 250px;
}

figure {
    opacity: 1;
    overflow: hidden;
    background: white;
}

.hover-zoom-in figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}
.hover-zoom-in figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.8;
}