.sidebar-outer {
    position: relative;
}

@media (min-width: 768px) {
    .sticky {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 60px;
    }
}