@import '../../../node_modules/@fullcalendar/core/main.css';
@import '../../../node_modules/@fullcalendar/daygrid/main.css';
@import '../../../node_modules/@fullcalendar/list/main.css';

.event-style {
  cursor: pointer;
}

.multiline-event > .fc-content {
  white-space: normal;
}