.dialog-shadow {
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.signup-link {
    font-size: 14px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}