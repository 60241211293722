.space-top {
    padding-top: 200px;
}

.empty-page {
    min-height: 100vh;
}

@media (max-width: 1200px){
    .space-top {
        padding-top: 80px;
    }
}